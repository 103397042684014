import React, { Component } from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import ContactForm from '../components/parts/ContactForm'
import ContactInformation from '../components/parts/ContactInformation'

export default class contact extends Component {
    render() {
        return (
            <Layout>
                <SEO title="Contact" description="Contact Tiger Press. Questions? Product ideas? Need custom prints? Looking to grow your brand? Make your next move and start right now." />
                <div className="contact-wrap">
                    <h1 className="page-title">Contact</h1>
                    <h2 className="page-subtitle">Questions? Product ideas? Need custom prints? Looking to grow your brand? Make your next move and start right now.</h2>
                    <main>
                        <ContactForm />
                    </main>
                    <section>
                        <ContactInformation />
                    </section>

                </div>
                
            </Layout>
        )
    }
}
